

import { Component, Vue } from 'nuxt-property-decorator';
import { PaymentTypes } from '~/operations/messages';

import ReducedPriceComparison from '~/components/organisms/form/ReducedPriceComparison.vue';

@Component({
  components: {
    ReducedPriceComparison,
  }
})
export default class InstallmentCalculatorMolecule extends Vue {

  PaymentTypes = PaymentTypes;

  userZipCode: number = null;
  userOilAmount: number = 3000;
  userZipCodeSupportMessage: string = '';
  userOilAmountSupportMessage: string = '';
  purchaseSteps = [
    {
      icon: 'piggy-bank-solid.svg',
      text: 'Vergleichen & bestellen',
    },
    {
      icon: 'truck-solid.svg',
      text: 'Lieferung erhalten',
    },
    {
      icon: 'coins-solid.svg',
      text: 'Bequem in Raten zahlen',
    },
  ]

}

