
// Libraries
import { Vue, Component } from "nuxt-property-decorator";
import { ButtonText, EsyOilContact, PaymentTypes } from "~/operations/messages";

// Components
import AkkordeonListItem from "~/components/molecules/text/AkkordeonListItem.vue";
import PageJumpNavigation from "~/components/molecules/nav/PageJumpNavigation.vue";
import InstallmentPurchase from "~/components/organisms/form/InstallmentPurchase.vue";
import { generateMetaTags } from "~/operations/meta-helper-functions";
import { getMkLogin } from "@/operations";
import sendGtmEvent from "~/operations/send-gtm-event";

@Component({
  components: {
    AkkordeonListItem,
    PageJumpNavigation,
    InstallmentPurchase,
  },
})
export default class RatenkaufView extends Vue {
  head() {
    return {
      ...generateMetaTags(this.$route.path, "ratenkauf"),
      link: [{ rel: "canonical", href: `${this.$route.fullPath}` }],
    };
  }

  ButtonText = ButtonText;
  EsyOilContact = EsyOilContact;
  PaymentTypes = PaymentTypes;

  JumpNavItems = [
    {
      name: `esyoil-${PaymentTypes.guaranteedPayment}`,
      title: "Springen Sie zum Bereich für Infos zu: Was ist die CO2-Abgabe",
      href: { path: "/infos/ratenkauf", hash: "#first-anker" },
    },
    {
      name: `${PaymentTypes.guaranteedPayment} - Vorteile`,
      title: "Springen Sie zum Bereich für Infos zu: Stromteuerung dämpfen",
      href: { path: "/infos/ratenkauf", hash: "#second-anker" },
    },
    {
      name: `${PaymentTypes.guaranteedPayment} in 6 Schritten`,
      title:
        "Springen Sie zum Bereich für Infos zu: Ist die CO2-Abgabe gerecht",
      href: { path: "/infos/ratenkauf", hash: "#third-anker" },
    },
    {
      name: `${PaymentTypes.guaranteedPayment} von A - Z`,
      title:
        "Springen Sie zum Bereich für Infos zu: Ist die CO2-Abgabe gerecht",
      href: { path: "/infos/ratenkauf", hash: "#fourth-anker" },
    },
    {
      name: `Wichtige Fragen zum ${PaymentTypes.guaranteedPayment}`,
      title:
        "Springen Sie zum Bereich für Infos zu: Ist die CO2-Abgabe gerecht",
      href: { path: "/infos/ratenkauf", hash: "#fifth-anker" },
    },
    {
      name: "Rund um die Bonitätsprüfung",
      title:
        "Springen Sie zum Bereich für Infos zu: Ist die CO2-Abgabe gerecht",
      href: { path: "/infos/ratenkauf", hash: "#sixth-anker" },
    },
    {
      name: `Ablehnung Ihres ${PaymentTypes.guaranteedPayment}s`,
      title:
        "Springen Sie zum Bereich für Infos zu: Ist die CO2-Abgabe gerecht",
      href: { path: "/infos/ratenkauf", hash: "#seventh-anker" },
    },
    {
      name: "Was tun bei Ablehnung",
      title:
        "Springen Sie zum Bereich für Infos zu: Ist die CO2-Abgabe gerecht",
      href: { path: "/infos/ratenkauf", hash: "#eighth-anker" },
    },
  ];

  FaqList = [
    {
      question: `Wie funktioniert der ${PaymentTypes.guaranteedPayment} über Payla?`,
      answer: `Bestellen Sie jetzt und zahlen Sie später bequem in monatlichen Raten. Sie haben die Wahl zwischen 3, 6, 9, 12, 18 und 24 Monatsbeträgen. Alle Zahlungsdetails Ihrer Heizölbestellung mit ${PaymentTypes.guaranteedPayment} werden Ihnen während des Bestellvorgangs aufgeschlüsselt angezeigt. Den genauen Ratenplan bekommen Sie nach Ihrer Heizöllieferung von unserem Partner Payla per E-Mail zugesandt.`,
      visibility: false,
    },
    {
      question: `Welche Bedingungen müssen für einen ${PaymentTypes.guaranteedPayment} erfüllt sein?`,
      answer: `Um einen ${PaymentTypes.guaranteedPayment} über esyoil abschließen zu können, müssen folgende Bedingungen erfüllt sein: der Höchstbestellwert der Bestellung beträgt 3.000 € (inkl. MwSt.), die Bestellung kann nur für eine Entladestelle abgeschlossen werden, die Bestellung darf keine abweichende Rechnungsanschrift enthalten, gute Bonität vorausgesetzt.`,
      visibility: false,
    },
    {
      question: `Wann lohnt sich ein Heizöl ${PaymentTypes.guaranteedPayment} für mich?`,
      answer: `Der Heizölpreis ist von vielen Faktoren abhängig und ändert sich mehrmals täglich. Es lohnt sich also immer, ihn gut zu beobachten. Ist der Heizölpreis gerade günstig, Sie sind aber nicht liquide? Dann nutzen Sie unseren Preisrechner und vergleichen Sie unsere Angebote zum ${PaymentTypes.guaranteedPayment}. Gut zu wissen: esyoil bietet Ihnen viele nützliche Informationen und Tools, um einen guten Kaufzeitpunkt abzupassen und auch den eigenen Vorrat gut im Blick zu haben. Schauen Sie doch mal in Mein Konto unter Benachrichtigungen und testen Sie z.B. unseren “Preiswecker”.`,
      visibility: false,
    },
    {
      question: `Gibt es einen Höchstbestellwert bei einem ${PaymentTypes.guaranteedPayment} über Payla?`,
      answer: `Ein ${PaymentTypes.guaranteedPayment} ist nur bei einem Gesamtbestellwert bis 3.000 € möglich.`,
      visibility: false,
    },
    {
      question: `Wie viele Entladestellen sind bei einem ${PaymentTypes.guaranteedPayment} möglich?`,
      answer: `Die Zahlart "${PaymentTypes.guaranteedPayment}" ist nur bei einer Entladestelle möglich.`,
      visibility: false,
    },
    {
      question: `Kann ich den ${PaymentTypes.guaranteedPayment} per Telefon abschließen?`,
      answer: `Ein Bestellung mit ${PaymentTypes.guaranteedPayment} ist ausschließlich direkt über unsere Homepage www.esyoil.com oder unsere App "Heizölpreise" möglich. Aus Gründen des Datenschutzes dürfen unsere Mitarbeiter eine Bestellung mit ${PaymentTypes.guaranteedPayment} telefonisch nicht entgegennehmen. Für Rückfragen oder Hilfestellung während des Bestellprozesses stehen wir Ihnen gern unter unserer Hotline ${EsyOilContact.customerServicePhone} (${EsyOilContact.customerServiceTimes}) zur Verfügung.`,
      visibility: false,
    },
    {
      question: `Erhalte ich eine ${PaymentTypes.invoice} beim ${PaymentTypes.guaranteedPayment}?`,
      answer: `Für Ihre Unterlagen bekommen Sie bei einer Bestellung per ${PaymentTypes.guaranteedPayment} Ihre ${PaymentTypes.invoice} von der agital.online GmbH. Ihren Ratenplan und alle Informationen zur Zahlung erhalten Sie gesondert per E-Mail von Payla.`,
      visibility: false,
    },
    {
      question: "Wann ist die erste Rate fällig?",
      answer:
        "Die erste Rate wird in der Regel zum 28. des Folgemonats nach der Heizöllieferung fällig. Alle Zahlungsdetails und den genauen Ratenplan bekommen Sie von unserem Partner Payla per E-Mail zugesandt.",
      visibility: false,
    },
    {
      question: `Wie viel Zinsen zahle ich beim ${PaymentTypes.guaranteedPayment} über Payla?`,
      answer:
        "Die Zinsen fallen unterschiedlich aus. Nachdem Sie einen Händler in unserem Heizöl-Preisvergleich ausgewählt haben, sehen Sie in der Bestellübersicht den Finanzierungsbetrag. Nun können Sie zwischen 3, 6, 9, 12, 18 und 24 Monatsraten wählen. Dahinter finden Sie den Gesamtbetrag. Hieraus können Sie sich die Zinsen erschließen. Probieren Sie es doch einfach mal aus.",
      visibility: false,
    },
    {
      question: "Was kann ich tun, wenn ich meinen Ratenplan verloren habe?",
      answer:
        "Bei Fragen oder Unklarheiten zu Ihrem Ratenplan gehen Sie mit Ihrer Payla-Zahlungsreferenz in das Payla-Serviceportal (https://payla-portal.com) und kontaktieren uns via Chat-Funktion oder senden Sie uns eine E-Mail an customer-service@payla-mail.com.",
      visibility: false,
    },
    {
      question: `Kann ich trotz eines laufenden ${PaymentTypes.guaranteedPayment}s eine neue Bestellung aufgeben?`,
      answer: `Ja, Sie können trotz eines bestehenden Ratenkaufs eine neue Bestellung mit Ratenkauf aufgeben. Der neue Ratenplan läuft dann parallel zu Ihrem bestehenden Ratenplan. Bei Fragen oder Unklarheiten zu Ihrem Ratenplan gehen Sie mit Ihrer Payla-Zahlungsreferenz in das Payla-Serviceportal (https://payla-portal.com) und kontaktieren uns via Chat-Funktion oder senden Sie uns eine E-Mail an customer-service@payla-mail.com.`,
      visibility: false,
    },
    {
      question: `${PaymentTypes.guaranteedPayment} kann nicht durchgeführt werden - Was bedeutet das?`,
      answer: `Ihr Ratenkauf wurde von Payla abgelehnt. Bitte kontrollieren Sie Ihre Eingaben oder versuchen es zu einem späteren Zeitpunkt erneut. Bei Unklarheiten zu Ihrem Ratenkauf, kontaktieren Sie Payla direkt unter customer-service@payla-mail.com. Alternativ können Sie für Ihren Heizölkauf auch eine der anderen Zahlarten wählen.`,
      visibility: false,
    },
    {
      question: `${PaymentTypes.guaranteedPayment} wird abgelehnt. Wie bestelle ich nun mein Heizöl?`,
      answer: `In unserem Preisrechner stehen Ihnen für Ihren Heizölkauf noch alternative Zahlarten zur Verfügung, z. B. ${PaymentTypes.cash} oder Zahlung per ${PaymentTypes.ec}.`,
      visibility: false,
    },
  ];

  tracking(val, item) {
    this.FaqList.map((elem) => {
      if (elem.question === item.question) {
        elem.visibility = !elem.visibility;
      }
      return elem;
    });
    if (val.visibility) {
      sendGtmEvent(this, {
        category: "FAQ",
        label: item.question,
        action: "click",
      });
    }
  }

  get _getMkLogin() {
    return getMkLogin();
  }
}
